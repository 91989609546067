
  import { Vue, Component } from "vue-property-decorator";
  import { cmsApi } from "@/cmsApi";
  import { IAssociatedEvidence, IEvidence } from "@/interfaces";

  @Component
  export default class Evidence extends Vue {
    evidences: IEvidence[] = [];
    associatedEvidences: IAssociatedEvidence[] = [];

    public async mounted() {
      cmsApi.getEvidence().then((resp) => {
        this.evidences = resp.data.items;
      });
      cmsApi.getAssociatedEvidence().then((resp) => {
        this.associatedEvidences = resp.data.items;
      });
    }
  }
